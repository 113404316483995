import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import {
  ButtonMain,
  FullTable,
  FullTableAccordionMain,
  PriceConvertor,
  ReadMore,
  SearchInput,
  ShareBtn,
} from "components";
import { CharityDetails } from "./charityDetails";
import { fundraiserStatuses } from "../../fundraisers";
import { useModal, useWindowDimensions } from "utils";
import { Images, Svg } from "assets";
import { CHARITY_TABLE_ACTION_TYPE } from "../../charities/fragments";
import {
  contributionsAccordionDetails,
  contributionsBody,
  contributionsHead,
} from "./constants";
import { ContributionCard } from "./contributionCard";

export const EventInfo = () => {
  const [showMoreCharities, setShowMoreCharities] = useState(false);
  const { fundraiserById, donationFundraiser } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
    donationFundraiser: state.donation.donationFundraiser,
  }));

  const navigate = useNavigate();
  const { openModal } = useModal();
  const { width } = useWindowDimensions();
  const isMemory = fundraiserById?.type === "MEMORY";

  const showCharity = fundraiserById?.charities?.length
    ? fundraiserById?.charities.slice(0, 2)
    : [];

  const showedAnotherCharity = fundraiserById?.charities?.length
    ? fundraiserById?.charities.slice(2)
    : [];

  return (
    <>
      <div className="event-details-info-box-desktop">
        <div className="event-details-info-box">
          <div className="event-details_img-charities">
            <div className="event-details-image">
              <img
                src={
                  fundraiserById?.images?.length
                    ? fundraiserById?.images[
                        fundraiserById?.mainImage
                          ? fundraiserById?.mainImage
                          : 0
                      ]?.url
                    : isMemory
                    ? Images.needCardFrame
                    : Images.needCardFrameWhite
                }
                alt="compaign"
              />
            </div>

            <p className="created-by-text">
              <span style={{ fontWeight: 700 }}>Created by: </span>
              {`${fundraiserById?.owner?.firstName} ${fundraiserById?.owner?.lastName}`}{" "}
              on {moment(fundraiserById?.createdAt).format("MM/DD/YYYY")}
            </p>

            <div className="description-wrapper">
              <h3 className="description-title">Description</h3>

              {fundraiserById?.description && (
                <ReadMore maxLength={430} text={fundraiserById?.description} />
              )}
            </div>
          </div>

          <div className="event-details_info-organizer">
            <div className="event-details-info-section">
              <div className="event-details-info_type-displayId">
                <p
                  className="event-details-info-type"
                  style={{ background: isMemory ? "#E0E1E2" : "" }}
                >
                  {isMemory ? "In Memory of" : "In Celebration"}
                </p>

                {fundraiserById?.displayId && (
                  <p
                    className="event-details-info-displayId"
                    style={{ background: isMemory ? "#E0E1E2" : "" }}
                  >
                    {fundraiserById?.displayId}
                  </p>
                )}
              </div>

              <p className="event-details-price">
                Total Contributions:{" "}
                {PriceConvertor({ price: fundraiserById?.balance })}
              </p>

              <div className="event-details-info_date">
                <div className="event-details-names-block">
                  {fundraiserById?.firstName && (
                    <p className="event-details-firstName">
                      {fundraiserById?.firstName}{" "}
                    </p>
                  )}
                  {fundraiserById?.lastName && (
                    <p className="event-details-firstName">
                      {fundraiserById?.lastName}{" "}
                    </p>
                  )}
                  {fundraiserById?.middleName && (
                    <p className="event-details-firstName">
                      {fundraiserById?.middleName}{" "}
                    </p>
                  )}
                </div>
                {fundraiserById?.celebrationDate && (
                  <p className="date-off-celebration">
                    Date of Celebration:{" "}
                    {moment(fundraiserById?.celebrationDate).format(
                      "MM/DD/YYYY"
                    )}
                  </p>
                )}

                {fundraiserById?.birthDate && (
                  <p className="date-off-celebration">
                    Date of Birth:{" "}
                    {moment(fundraiserById?.birthDate).format("MM/DD/YYYY")}
                  </p>
                )}

                {fundraiserById?.deathDate && (
                  <p className="date-off-celebration">
                    Date of Died:{" "}
                    {moment(fundraiserById?.deathDate).format("MM/DD/YYYY")}
                  </p>
                )}
              </div>

              <div className="event-details-donate-and-share">
                {fundraiserById?.status === fundraiserStatuses.LIVE && (
                  <ButtonMain
                    classNameWrapper={`event-details-donate-btn ${
                      isMemory ? "event-details-donate-btn-memory" : ""
                    }`}
                    startIcon={<Svg.HeartSvg />}
                    text="Donate now"
                    type="button"
                    onClick={() => navigate(`/donate/${fundraiserById?.id}`)}
                  />
                )}
                <ShareBtn
                  buttonText="Share"
                  background="transparent"
                  border="1px solid #E0E1E2"
                />
              </div>

              {!!showCharity?.length && (
                <div className="event-details-charities-wrapper">
                  <h3 className="event-details-charities-title">Charities</h3>
                  <div>
                    {!!showCharity.length && (
                      <div className="event-details-charities-box">
                        {showCharity?.map((item, index) => (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              openModal(<CharityDetails charity={item} />, {
                                padding: "noPaddings",
                                noShowCloseBrn: true,
                              });
                            }}
                            type={"button"}
                            className="event-details-charity"
                            key={index}
                          >
                            <div className="event-details-charities_svg">
                              {isMemory ? (
                                <Svg.FundraiserFrameMemorySvg />
                              ) : (
                                <Svg.FundraiserFrameSvg />
                              )}
                            </div>

                            <p>{item?.charity?.name}</p>
                          </button>
                        ))}
                      </div>
                    )}

                    {!!showedAnotherCharity.length && (
                      <div>
                        {showMoreCharities && (
                          <div
                            className="event-details-charities-box"
                            style={{ marginTop: "4px" }}
                          >
                            {showedAnotherCharity?.map((item, index) => (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  openModal(<CharityDetails charity={item} />, {
                                    padding: "noPaddings",
                                    noShowCloseBrn: true,
                                  });
                                }}
                                type={"button"}
                                className="event-details-charity"
                                key={index}
                              >
                                <div className="event-details-charities_svg">
                                  {isMemory ? (
                                    <Svg.FundraiserFrameMemorySvg />
                                  ) : (
                                    <Svg.FundraiserFrameSvg />
                                  )}
                                </div>

                                <p>{item?.charity?.name}</p>
                              </button>
                            ))}
                          </div>
                        )}

                        <button
                          onClick={() =>
                            setShowMoreCharities((prevState) => !prevState)
                          }
                          className="event-details-charities_see-button"
                        >
                          {showMoreCharities
                            ? "Hide More Charities"
                            : "See More Charities"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!!donationFundraiser?.donations?.length && (
          <div className="contributions-wrapper">
            <h2 className="contributions-title">Contributions</h2>

            <FullTable
              classNameWrapper="contributions-full-table"
              head={contributionsHead(width)}
              body={contributionsBody(width)}
              loadingType={CHARITY_TABLE_ACTION_TYPE}
              list={donationFundraiser?.donations}
            />
          </div>
        )}
      </div>

      <div className="event-details-info-box-mobile">
        <div className="event-details-info-box">
          <div className="event-details-image">
            <img
              src={
                fundraiserById?.images?.length
                  ? fundraiserById?.images[
                      fundraiserById?.mainImage ? fundraiserById?.mainImage : 0
                    ]?.url
                  : isMemory
                  ? Images.needCardFrame
                  : Images.needCardFrameWhite
              }
              alt="compaign"
            />
          </div>

          <p className="created-by-text">
            <span style={{ fontWeight: 700 }}>Created by: </span>
            {`${fundraiserById?.owner?.firstName} ${fundraiserById?.owner?.lastName}`}{" "}
            on {moment(fundraiserById?.createdAt).format("MM/DD/YYYY")}
          </p>

          <div className="event-details-info-section">
            <div className="event-details-info_type-displayId">
              <p
                className="event-details-info-type"
                style={{ background: isMemory ? "#E0E1E2" : "" }}
              >
                {isMemory ? "In Memory of" : "In Celebration"}
              </p>

              {fundraiserById?.displayId && (
                <p
                  className="event-details-info-displayId"
                  style={{ background: isMemory ? "#E0E1E2" : "" }}
                >
                  {fundraiserById?.displayId}
                </p>
              )}
            </div>

            <p className="event-details-price">
              Total Contributions:{" "}
              {PriceConvertor({ price: fundraiserById?.balance })}
            </p>

            <div className="event-details-info_date">
              <div className="event-details-names-block">
                {fundraiserById?.firstName && (
                  <p className="event-details-firstName">
                    {fundraiserById?.firstName}{" "}
                  </p>
                )}
                {fundraiserById?.lastName && (
                  <p className="event-details-firstName">
                    {fundraiserById?.lastName}{" "}
                  </p>
                )}
                {fundraiserById?.middleName && (
                  <p className="event-details-firstName">
                    {fundraiserById?.middleName}{" "}
                  </p>
                )}
              </div>
              {fundraiserById?.celebrationDate && (
                <p className="date-off-celebration">
                  Date of Celebration:{" "}
                  {moment(fundraiserById?.celebrationDate).format("MM/DD/YYYY")}
                </p>
              )}

              {fundraiserById?.birthDate && (
                <p className="date-off-celebration">
                  Date of Birth:{" "}
                  {moment(fundraiserById?.birthDate).format("MM/DD/YYYY")}
                </p>
              )}

              {fundraiserById?.deathDate && (
                <p className="date-off-celebration">
                  Date of Died:{" "}
                  {moment(fundraiserById?.deathDate).format("MM/DD/YYYY")}
                </p>
              )}
            </div>

            <div className="event-details-donate-and-share">
              {fundraiserById?.status === fundraiserStatuses.LIVE && (
                <ButtonMain
                  classNameWrapper={`event-details-donate-btn ${
                    isMemory ? "event-details-donate-btn-memory" : ""
                  }`}
                  startIcon={<Svg.HeartSvg />}
                  text="Donate now"
                  type="button"
                  onClick={() => navigate(`/donate/${fundraiserById?.id}`)}
                />
              )}
              <ShareBtn
                buttonText="Share"
                background="transparent"
                border="1px solid #E0E1E2"
              />
            </div>

            {!!showCharity?.length && (
              <div className="event-details-charities-wrapper">
                <h3 className="event-details-charities-title">Charities</h3>
                <div>
                  {!!showCharity.length && (
                    <div className="event-details-charities-box">
                      {showCharity?.map((item, index) => (
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            openModal(<CharityDetails charity={item} />, {
                              padding: "noPaddings",
                              noShowCloseBrn: true,
                            });
                          }}
                          type={"button"}
                          className="event-details-charity"
                          key={index}
                        >
                          <div className="event-details-charities_svg">
                            {isMemory ? (
                              <Svg.FundraiserFrameMemorySvg />
                            ) : (
                              <Svg.FundraiserFrameSvg />
                            )}
                          </div>

                          <p>{item?.charity?.name}</p>
                        </button>
                      ))}
                    </div>
                  )}

                  {!!showedAnotherCharity.length && (
                    <div>
                      {showMoreCharities && (
                        <div
                          className="event-details-charities-box"
                          style={{ marginTop: "4px" }}
                        >
                          {showedAnotherCharity?.map((item, index) => (
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openModal(<CharityDetails charity={item} />, {
                                  padding: "noPaddings",
                                  noShowCloseBrn: true,
                                });
                              }}
                              type={"button"}
                              className="event-details-charity"
                              key={index}
                            >
                              <div className="event-details-charities_svg">
                                {isMemory ? (
                                  <Svg.FundraiserFrameMemorySvg />
                                ) : (
                                  <Svg.FundraiserFrameSvg />
                                )}
                              </div>

                              <p>{item?.charity?.name}</p>
                            </button>
                          ))}
                        </div>
                      )}

                      <button
                        onClick={() =>
                          setShowMoreCharities((prevState) => !prevState)
                        }
                        className="event-details-charities_see-button"
                      >
                        {showMoreCharities
                          ? "Hide More Charities"
                          : "See More Charities"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="description-wrapper">
            <h3 className="description-title">Description</h3>

            {fundraiserById?.description && (
              <ReadMore maxLength={430} text={fundraiserById?.description} />
            )}
          </div>

          {!!donationFundraiser?.donations?.length && (
            <div className="contributions-wrapper">
              <h2 className="contributions-title">Contributions</h2>

              <SearchInput
                placeholder={"Search..."}
                searchKey={"name"}
                className="contributions-search-input"
              />

              {width > 767 ? (
                <FullTableAccordionMain
                  classNameWrapper="contributions-accordion"
                  head={contributionsHead(width)}
                  body={contributionsBody(width)}
                  accordionDetails={contributionsAccordionDetails}
                  list={donationFundraiser?.donations}
                />
              ) : (
                <div className="contribution-cards-block">
                  {donationFundraiser?.donations.map((item, index) => (
                    <ContributionCard item={item} key={index} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
