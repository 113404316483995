import React from "react";
import { useNavigate } from "react-router";
import { Svg } from "assets";

export const StartEvent = () => {
  const navigate = useNavigate();

  const goToPage = (path, eventType) => {
    navigate(path, {
      state: {
        eventType,
      },
    });
  };

  return (
    <div className="start-event-page">
      <div className="start-event-box">
        <div className="start-event-title_desc">
          <h2 className="start-event-title">Choose event type!</h2>
        </div>

        <div className="dark-white-block">
          <div className="dark-img-wrapper">
            <div className="dark-img-button_arrow">
              <button
                className="dark-img-button"
                onClick={() => goToPage("/in-memory-of", "memory")}
              >
                {/*<Svg.InMemorySvg />*/}
                <p>In Memory</p>
              </button>
              {/*<Svg.WhiteArrowSvg className="dark-img-arrow" />*/}
            </div>

            <Svg.BlackSvg className="dark-img-block" />
          </div>

          <div className="white-img-wrapper">
            <div className="white-img-button_arrow">
              {/*<Svg.BlackArrowSvg className="white-img-arrow" />*/}

              <button
                className="white-img-button"
                onClick={() => goToPage("/in-celebration", "celebration")}
              >
                {/*<Svg.InCelebration />*/}
                <p>In Celebration</p>
              </button>
            </div>

            <Svg.WhiteSvg className="white-img-block" />
          </div>
        </div>
      </div>
    </div>
  );
};
