import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import { DateInput, InputMain } from "components";
import { SaveParams } from "utils";
import { Stepper } from "./stepper";

export const DeceasedInformation = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;
  const { handleSubmit, control, reset } = useForm();

  useEffect(() => {
    if (info?.celebration) {
      reset(info?.celebration);
    }
  }, [info]);

  const handleCreate = (data) => {
    const params = {
      ...info,
      step: 2,
    };

    params.celebration = {
      ...data,
    };
    SaveParams(location?.pathname, navigator, params);
  };

  return (
    <form className="in-celebration-box" onSubmit={handleSubmit(handleCreate)}>
      <div className="in-celebration-content">
        <div className="container">
          <div className="in-celebration-form-wrapper deceased-information-wrapper">
            <div className="in-celebration-form-header">
              <p className="in-celebration-form-header-title">
                Event Information
              </p>

              <p className="in-celebration-form-header-desc">
                Provide information below for the event you are creating
              </p>
            </div>
            <div className="in-celebration-form-content">
              <InputMain
                className="celebration-input"
                name="firstName"
                control={control}
                placeholder="ex. Richard"
                labelHeader="First Name"
                rules={{
                  required: "First name is required",
                  validate: {
                    trimmed: (value) =>
                      value.trim() !== "" ||
                      "First name cannot be empty or just spaces",
                  },
                }}
              />

              <InputMain
                className="celebration-input"
                name="lastName"
                control={control}
                placeholder="ex. Smith"
                labelHeader="Last Name"
                rules={{
                  required: "Last name is required",
                  validate: {
                    trimmed: (value) =>
                      value.trim() !== "" ||
                      "Last name cannot be empty or just spaces",
                  },
                }}
              />

              <InputMain
                className="celebration-input"
                name="middleName"
                control={control}
                placeholder="ex. R.S"
                labelHeader="Middle Name"
              />

              <InputMain
                className="celebration-input"
                name="relationship"
                control={control}
                placeholder="ex. son"
                labelHeader="Relationship to the Celebrant"
              />

              <DateInput
                className="celebration-input"
                name="celebrationDate"
                label="Date of Celebration"
                placeholder="Choose Date"
                control={control}
                min={new Date()}
                rules={{
                  required: "Date of celebration is required",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Stepper />
    </form>
  );
};
