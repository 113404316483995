import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Radio } from "@mui/material";
import { BackBtn, ButtonMain, PriceRow } from "components";
import {
  donationActions,
  fundraiserActions,
  httpRequestsOnSuccessActions,
} from "store";
import { Images, Svg } from "assets";
import { FindLoad, FindSuccess } from "utils";
import { amountEnums, ACTION_TYPE } from "./fragments";
import Checkbox from "@mui/material/Checkbox";

export const DonateCheckoutForm = () => {
  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [payType, setPayType] = useState("card");
  const [agree, setAgree] = useState(false);
  const { handleSubmit, register, setValue, reset, watch } = useForm({});
  const loadCreation = FindLoad(ACTION_TYPE);
  const success = FindSuccess(ACTION_TYPE);

  useEffect(() => {
    if (success) {
      reset({});
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
      navigate("/payment-success");
    }
  }, [success]);

  useEffect(() => {
    return () => {
      dispatch(fundraiserActions.resetFundraiserById());
    };
  }, []);

  const onSubmit = async (event) => {
    // if (charities?.length > 0) {
    setLoader(true);
    setErrors({});

    if (!stripe || !elements) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
        // billing_details: {
        //     name: event.target.name.value,
        // },
      });

      if (error) {
        switch (error.code) {
          case "incomplete_number":
          case "invalid_number":
            setErrors((prev) => ({
              ...prev,
              cardNumber: "Please enter a valid card number.",
            }));
            break;
          case "incomplete_expiry":
          case "invalid_expiry_year_past":
          case "invalid_expiry_year":
          case "invalid_expiry_month":
            setErrors((prev) => ({
              ...prev,
              expiry: "Please enter a valid expiry date.",
            }));
            break;
          case "incomplete_cvc":
          case "invalid_cvc":
            setErrors((prev) => ({
              ...prev,
              cvc: "Please enter a valid CVV.",
            }));
            break;
          default:
            setErrors((prev) => ({
              ...prev,
              els: error.message,
            }));
        }
      } else {
        const params = {
          amount: +event?.amount,
          email: event?.email,
          donorName: event?.donorName,
          fundraiser: fundraiserById?.id,
          pmtMethod: paymentMethod?.id,
          // charities: charities,
        };

        event?.description ? (params.description = event?.description) : null;
        dispatch(donationActions.createDonations(params));
      }
    } catch (e) {
      setErrors((prev) => ({
        ...prev,
        els: e.data.message,
      }));
    } finally {
      setLoader(false);
    }
    // }

    // else {
    //   charityRef.current.scrollIntoView({
    //     behavior: "smooth",
    //     block: "center",
    //   });
    //   setErrors("charity");
    // }
  };

  // const handleCheck = (id) => {
  //   let newList = [...charities];
  //
  //   if (errors === "charity") setErrors({});
  //
  //   const current = charities.find((item) => item === id);
  //   if (current) {
  //     newList = newList.filter((item) => item !== id);
  //   } else {
  //     newList.push(id);
  //   }
  //   setCharities(newList);
  // };

  const amount = watch("amount") ? watch("amount") : 0;

  return (
    <form className="donate-page" onSubmit={handleSubmit(onSubmit)}>
      <div className="donate-page-block">
        <div className="donate-page-back-btn-box">
          <BackBtn
            handleClick={() => navigate(`/event/${fundraiserById?.id}`)}
          />
        </div>

        <div className="donate-page-wrapper">
          <div className="donate-page-amount-wrapper">
            <div>
              <p className="donate-page-card-title">
                Write the Amount you want to donate*
              </p>
              <div className="donate-page-card-box">
                <div className="donate-page-amount-box">
                  <div>
                    <p>USD</p>
                  </div>
                  <input
                    type="number"
                    name={"amount"}
                    required={true}
                    {...register("amount", { required: true })}
                  />
                </div>
                <div className="donate-page-amounts">
                  {amountEnums.map((item, index) => (
                    <button
                      type={"button"}
                      onClick={() => setValue("amount", item?.value)}
                      key={index}
                      className="donate-page-amount-btn"
                    >
                      {item?.key}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <div className="donater-information-box">
              <div>
                <p className="donate-page-card-title">
                  Write Your Name or Nickname*
                </p>
                <input
                  type="text"
                  className="donate-page-nickname"
                  placeholder="Name..."
                  name={"donorName"}
                  required={true}
                  {...register("donorName", { required: true })}
                />
                <p className="donate-page-nickname-subtitle">
                  *Use a nickname instead of your real name to keep your
                  identity private.
                </p>
              </div>

              <div>
                <p className="donate-page-card-title">Email*</p>
                <input
                  type="email"
                  className="donate-page-nickname"
                  placeholder="example@gmail.com"
                  name={"email"}
                  required={true}
                  {...register("email", { required: true })}
                />
              </div>

              <div>
                <p className="donate-page-card-title">Your Message</p>
                <textarea
                  className="donate-page-text-area"
                  placeholder="Write something you want to say..."
                  name={"description"}
                  {...register("description", { required: false })}
                />
              </div>
            </div>

            {/*<div>*/}
            {/*  <p*/}
            {/*    className="donate-page-card-title"*/}
            {/*    style={{ color: itemsColor }}*/}
            {/*  >*/}
            {/*    Choose Charity*/}
            {/*  </p>*/}
            {/*  <div*/}
            {/*    className="donate-page-card-box"*/}
            {/*    style={{*/}
            {/*      gap: "24px",*/}
            {/*      borderColor: errors === "charity" ? "red" : "#E0E1E2",*/}
            {/*      background: itemsBackColor,*/}
            {/*    }}*/}
            {/*    ref={charityRef}*/}
            {/*  >*/}
            {/*    {fundraiserById?.charities?.map((item, index) => (*/}
            {/*      <button*/}
            {/*        type={"button"}*/}
            {/*        onClick={() => handleCheck(item?.charity?._id)}*/}
            {/*        key={index}*/}
            {/*        className="donate-page-charity-btn"*/}
            {/*      >*/}
            {/*        <div>*/}
            {/*          <Svg.FundraiserFrame />*/}
            {/*          <p style={{ color: itemsColor }}>{item?.charity?.name}</p>*/}
            {/*        </div>*/}

            {/*        {charities?.includes(item?.charity?._id) ? (*/}
            {/*          <Svg.CheckBoxChecked />*/}
            {/*        ) : (*/}
            {/*          <Svg.CheckboxNotChecked />*/}
            {/*        )}*/}
            {/*      </button>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div>
              <p className="donate-page-card-title">
                Select the Payment Method
              </p>
              <div className="donate-page-card-box">
                {/*<StipeCard setPayType={setPayType} payType={payType} />*/}

                <div className="checkoutForm-wrapper">
                  <div className="card-title-box">
                    <div>
                      <Svg.CreditCard />
                      <p>Credit card or debit card</p>
                    </div>

                    <Radio
                      checked={payType === "card"}
                      onChange={() => setPayType("card")}
                      value="b"
                      name="card"
                      inputProps={{ "aria-label": "B" }}
                    />
                  </div>
                  <div className="credit-card-number">
                    <label className="checkoutForm-label" htmlFor="card">
                      Card Number
                      <CardNumberElement
                        id={"card"}
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: "#152E47",

                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                            invalid: {
                              color: "red",
                            },
                          },
                          classes: {
                            base: "checkoutForm-input",
                            invalid: "checkoutForm-input-error",
                          },
                          placeholder: "xxxx xxxx xxxx xxxx",
                        }}
                      />
                      <p className="card-errors">{error?.cardNumber}</p>
                    </label>
                  </div>

                  <div className="expiry-date-cvv">
                    <div className="credit-card-expiry-date">
                      <label className="checkoutForm-label" htmlFor="date">
                        MM/YY
                        <CardExpiryElement
                          id={"date"}
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "#152E47",

                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "red",
                              },
                            },
                            classes: {
                              base: "checkoutForm-input",
                              invalid: "checkoutForm-input-error",
                            },
                            placeholder: "MM / YY",
                          }}
                        />
                      </label>
                      <p className="card-errors">{error?.expiry}</p>
                    </div>

                    <div className="credit-card-cvv">
                      <label className="checkoutForm-label" htmlFor="cvv">
                        CVV
                        <CardCvcElement
                          id="cvv"
                          options={{
                            style: {
                              base: {
                                fontSize: "16px",
                                color: "#152E47",

                                "::placeholder": {
                                  color: "#aab7c4",
                                },
                              },
                              invalid: {
                                color: "red",
                              },
                            },
                            classes: {
                              base: "checkoutForm-input",
                              invalid: "checkoutForm-input-error",
                            },
                            placeholder: "XXX",
                          }}
                        />
                      </label>

                      <p className="card-errors">{error?.cvc}</p>
                    </div>
                  </div>
                  <p className="card-errors">{error?.els}</p>
                </div>
              </div>
              <div
                className="pay-type-wrapper"
                style={{
                  margin: "16px 0",
                  opacity: 0.5,
                }}
              >
                <div className="align-center">
                  <div className="pay-type-box">
                    <Svg.Google />
                    <p>Pay</p>
                  </div>
                  <p className="pay-type-text">Google Play</p>
                </div>

                <Radio
                  disabled={true}
                  checked={payType === "googlePay"}
                  onChange={() => setPayType("googlePay")}
                  value="b"
                  name="googlePay"
                  inputProps={{ "aria-label": "B" }}
                />
              </div>

              <div
                className="pay-type-wrapper"
                style={{
                  opacity: 0.5,
                }}
              >
                <div className="align-center">
                  <div className="pay-type-box">
                    <Svg.AppleSvg />
                    <p>Pay</p>
                  </div>
                  <p className="pay-type-text">Apple Play</p>
                </div>
                <Radio
                  disabled={true}
                  checked={payType === "applePay"}
                  onChange={() => setPayType("applePay")}
                  value="b"
                  name="applePay"
                  inputProps={{ "aria-label": "B" }}
                />
              </div>
            </div>
          </div>

          <div className="donate-page-contribution-wrapper">
            <p className="contribution-summary-title">Contribution Summary</p>
            <div className="donate-summary-box">
              <div className="donate-summary-head">
                <img
                  src={
                    fundraiserById?.images?.length
                      ? fundraiserById?.images[
                          fundraiserById?.mainImage
                            ? fundraiserById?.mainImage
                            : 0
                        ]?.url
                      : Images.needCardFrameWhite
                  }
                  alt="frame"
                />
                <p>{fundraiserById?.name}</p>
              </div>
              <div className="donate-summary-body">
                {/*<div*/}
                {/*  className={`donate-summary-info-box ${*/}
                {/*    isMemory ? "donate-summary-info-box-memory" : ""*/}
                {/*  }`}*/}
                {/*>*/}
                {/*  <p>Your Donation</p>*/}
                {/*  <PriceRow info={0} />*/}
                {/*</div>*/}
                <div className="donate-summary-info-box">
                  <p>Tax</p>
                  <PriceRow info={0} />
                </div>
                <div className="donate-summary-bold-info-box">
                  <p>Total</p>
                  <PriceRow info={+amount} />
                </div>
              </div>
            </div>

            <div className="terms-and-conditions">
              <Checkbox
                style={{ marginRight: 8 }}
                checked={agree}
                onClick={() => setAgree(!agree)}
              />
              <p>
                By clicking the Donate button, you agree to the
                <a
                  style={{ marginLeft: 4 }}
                  href={"/terms-and-conditions"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Terms & Conditions &
                </a>
                <a
                  href={"/terms-and-conditions"}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {" "}
                  the Privacy Policy.{" "}
                </a>
              </p>
            </div>
            <ButtonMain
              type="submit"
              text="Donate"
              className="generate-report-button"
              disabled={!agree ? true : loader || loadCreation}
              load={loader || loadCreation}
              startIcon={<Svg.HeartSvg />}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
